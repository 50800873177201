/* COMMON */

.container {
	@apply w-full h-full;
}

/* COMMON: Disabled */

.container:has(input:disabled) {
	@apply opacity-50;
}

.container:has(input:disabled) .placeholder,
.container:has(input:disabled) svg {
	@apply text-secondary !important;
}

.container:has(input:disabled) .notice {
	visibility: hidden;
}

/* COMMON: Input container */

.input-container {
	@apply relative p-[17px_12px];
}

.input-container > label {
	@apply flex gap-x-[8px] items-center;
}

/* COMMON: Input */

.input-container input:focus {
	@apply text-on-secondary-container;
	outline: none;
}

.input-container input {
	@apply font-body-medium flex-grow min-w-[3ch] bg-transparent text-secondary;
}

.input-container input::-webkit-inner-spin-button {
	display: none;
}

.input-container input::-webkit-calendar-picker-indicator {
	/*background: transparent;*/
	/*@apply pointer-events-none;*/
	@apply cursor-pointer;
}

.input-container input[type='date']::-webkit-calendar-picker-indicator,
.input-container input[type='time']::-webkit-calendar-picker-indicator,
.input-container input[type='datetime-local']::-webkit-calendar-picker-indicator {
	background: transparent;
	@apply pointer-events-none;
	@apply cursor-pointer;
}

.input-container input::placeholder {
	@apply text-transparent;
}

.s .input-container input,
.m .input-container input {
	@apply h-[20px];
}

/* Убираем стрелочки у числового Input'а (#355) */
.input-container input[type='number']::-webkit-outer-spin-button,
.input-container input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.input-container input[type='number'] {
	-moz-appearance: textfield;
}

/* COMMON: Placeholder */

.with-icon .placeholder {
	@apply ml-[26px];
}

.input-container:has(input:not(:placeholder-shown)) .placeholder,
.input-container:has(input:focus) .placeholder {
	@apply font-label-medium;
}

.input-container:has(input:focus) .placeholder {
	@apply font-label-medium text-primary;
}

/* COMMON: SVG */

.container svg {
	@apply text-secondary;
}

.container:has(input:focus) svg {
	@apply text-primary;
}

/* PART: Placeholder */

.placeholder {
	@apply pointer-events-none absolute text-secondary max-w-[80%] overflow-hidden whitespace-nowrap overflow-ellipsis gap-x-[4px] items-center;
}

.input-container:not(:has(input:focus)).input-container:has(input:placeholder-shown).input-container:has(
		.postfix:not(:empty)
	)
	.placeholder {
	width: calc(80% - 100px);
}

/* PART: Extra (right side controls) */

.extra {
	@apply flex gap-x-[10px] items-center min-w-0;
}

/* PART: Postfix (right side word) */

.postfix {
	@apply font-body-large text-secondary overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[130px] h-[20px];
}

.with-error .postfix {
	@apply text-error;
}

/* PART: Clear button */

.clear-button {
	@apply opacity-0;
}

/* PART: Notice (error, success messages) */

.notice {
	@apply min-h-[16px] font-label-medium max-w-full overflow-hidden overflow-ellipsis mt-[6px];
}

/* SIZES */

.s svg {
	@apply w-[16px] h-[16px];
}

.m svg {
	@apply w-[18px] h-[18px];
}

/* VARIANT: Underlined */

.underlined .input-container {
	@apply p-[8px_0] border-b-[2px] border-b-outline-variant;
}

.underlined .input-container:has(input:disabled) {
	@apply border-b-outline-variant !important;
}

.underlined .placeholder {
	@apply font-body-medium;
}

.underlined .input-container:has(input:not(:placeholder-shown)) .placeholder,
.underlined .input-container:has(input:focus) .placeholder {
	@apply top-[-8px] ml-0;
}

.underlined input {
	@apply font-body-medium text-on-primary-container;
}

.underlined .input-container:has(input:focus) {
	@apply border-b-primary;
}

.underlined .postfix {
	@apply font-body-medium;
}

/* VARIANT: Underlined: Modifications */

.underlined.s .input-container {
	@apply p-[3px_0];
}

.underlined.with-success .input-container {
	@apply border-b-green !important;
}

.underlined.with-error .input-container {
	@apply border-b-error !important;
}

/* VARIANT: Bordered */

.bordered .input-container {
	@apply p-[15px_11px] rounded-[8px] border border-outline-variant bg-background;
}

.bordered .input-container:has(input:disabled) {
	@apply border-outline-variant !important;
}

.bordered .input-container:has(input:focus) {
	@apply border-primary;
}

.bordered .input-container:has(input:not(:placeholder-shown)) .placeholder,
.bordered .input-container:has(input:focus) .placeholder {
	@apply p-[4px] bg-primary-100 top-[-12px] ml-0;
}

/* VARIANT: Bordered: Modifications */

.bordered.s .input-container {
	@apply p-[11px_12px];
}

.bordered.with-success .input-container {
	@apply border-green !important;
}

.bordered.with-error .input-container {
	@apply border-error !important;
}

/* VARIANT: Filled */

.filled .input-container {
	@apply p-[16px_12px] rounded-[8px] bg-secondary-container;
}

.filled .input-container:has(input:disabled) {
	@apply bg-secondary-container !important;
}

.filled .input-container:has(input:not(:placeholder-shown)) .placeholder,
.filled .input-container:has(input:focus) .placeholder {
	@apply top-[-8px] ml-0;
}

/* VARIANT: Filled: Modifications */

.filled.s .input-container {
	@apply p-[12px_12px];
}

.filled.with-success .input-container {
	@apply bg-green-95 !important;
}

.filled.with-error .input-container {
	@apply bg-error-90 !important;
}

/* MODIFICATION: With clear button */

.with-clear .clear-button {
	@apply opacity-100;
}

/* MODIFICATION: Success */

.with-success .notice,
.with-success .placeholder,
.with-success svg {
	@apply text-green !important;
}

/* MODIFICATION: Error */

.with-error .notice,
.with-error .placeholder,
.with-error svg {
	@apply text-error !important;
}

/* VARIANT: Filled */

.container:has(.uneven) {
	@apply w-max;
}

.uneven .input-container {
	@apply p-0;
}

.uneven .input-container input,
.uneven .input-container .placeholder {
	font-size: 20px;
	font-weight: 500;
}

.uneven .input-container:has(input:not(:placeholder-shown)) .placeholder,
.uneven .input-container:has(input:focus) .placeholder {
	/* @apply top-[-8px] ml-0; */
	display: none;
}

/* Filled: Disabled */

.uneven input:disabled {
	@apply !text-primary-10;
}

.container.uneven:has(input:disabled) {
	opacity: 1;
}
