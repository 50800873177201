.number,
.region {
	@apply relative;
}

.number__input,
.region__input {
	@apply font-headline-large text-primary-0 uppercase text-center;
	@apply w-[200px] h-[52px] px-[16px] border border-outline rounded-[8px];
}

.number__input {
	letter-spacing: 4px;
}

.region__input {
	@apply w-[100px] px-[22px];
}

.number__input:first-letter {
	@apply text-error !important;
}

.number__placeholder,
.region__placeholder {
	@apply font-headline-large text-primary-0 opacity-50;
	@apply absolute top-[6px] pointer-events-none w-full;
}

.number__placeholder {
	@apply text-center;
	letter-spacing: 4px;
}

.region__placeholder {
	@apply text-center left-0 w-full;
}

.number__input:focus + .number__placeholder,
.number__input:not(:placeholder-shown) + .number__placeholder {
	@apply opacity-0;
}

.region__input:focus + .region__placeholder,
.region__input:not(:placeholder-shown) + .region__placeholder {
	@apply opacity-0;
}

.region__flag {
	@apply absolute h-[12px] left-0 bottom-[3px] flex justify-center w-full gap-x-[8px] pointer-events-none items-center;
}

.country__btn {
	@apply border-outline uppercase w-[155px] font-title-large;
}

.country__btn:hover {
	@apply bg-transparent;
}

.country__btn:active {
	@apply outline-0 bg-transparent;
}
