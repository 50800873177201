.noUi-target {
	@apply bg-secondary-80 border-0 shadow-none rounded-[0] w-full;
	height: 6px;
}

.noUi-connect {
	@apply bg-primary;
}

.noUi-horizontal .noUi-handle {
	@apply w-5 h-5 rounded-[50%] shadow-none border-0 bg-primary;
}

.noUi-horizontal .noUi-handle::after,
.noUi-horizontal .noUi-handle::before {
	content: none;
}
