.input {
	@apply relative appearance-none;
	@apply w-[40px] h-[24px] rounded-[24px];
	@apply border-[1px] bg-neutralVariant-80;
	border-color: #e6e8f1;
}

.input:after {
	content: '';
	@apply absolute block w-[20px] h-[20px] rounded-[20px] bg-primary-100;
	@apply left-[1px] top-[1px];
	transition: left 0.16s ease;
}

.input.input--checked {
	@apply bg-primary border-primary;
}

.input.input--checked:after {
	@apply left-[17px];
}

.input:not(.input--checked) {
	filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
}

.input:not(:disabled) {
	cursor: pointer;
}
