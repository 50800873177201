.table {
	@apply rounded-[8px] rounded-bl-[0] rounded-br-[0] border-spacing-0 border-separate border border-neutral-90 border-b-0;
	width: calc(100vw - 293px - 32px - 32px);
}

/*
 * TABLE HEADER */

.table tr > th {
	@apply relative h-[72px] bg-surface p-[16px] text-left border-b border-b-neutral-90;
	@apply font-label-xlarge text-on-surface break-normal;
}

.table tr th:not(:last-child):after {
	@apply bg-outline-variant absolute w-[1px] h-[40px] right-0 top-[16px] content-[''];
}

/* Checkbox */
.table tr > th:nth-child(1) {
	@apply rounded-tl-[8px];
	width: calc((100% - 76px) / 5);
}

/* Action button */
.table tr > th:nth-child(6) {
	@apply w-[76px] rounded-tr-[8px];
}

/*
 * TABLE ROW */

.table tr > td {
	@apply font-body-large text-on-surface p-[16px];
}
