.button {
	@apply flex gap-x-[8px] justify-center items-center rounded-[8px] border border-transparent whitespace-nowrap;
}

.only-icon {
	/* Индикатор для IconButton */
}

/* Variants */
.red:active {
	@apply bg-error-80 outline outline-[3px] outline-primary-95;
}

.red {
	@apply bg-error text-on-primary;
}

.red:hover {
	@apply bg-error-30;
}

.red:disabled {
	@apply bg-error-30 opacity-30;
}

.error {
	@apply bg-error-90 text-error-40;
}

.error:hover {
	@apply bg-error-80 text-error-40;
}

.primary:active {
	@apply bg-primary-80 outline outline-[3px] outline-primary-95;
}

.primary {
	@apply bg-primary text-on-primary;
}

.primary:hover {
	@apply bg-primary-30;
}

.primary:active {
	@apply bg-primary-30 outline outline-[3px] outline-primary-95;
}

.primary:disabled {
	@apply bg-primary-30 opacity-30;
}

.grey {
	@apply bg-secondary-container text-on-secondary-container;
}

.grey:hover {
	@apply bg-secondary-90 outline outline-[1px] outline-secondary-95;
}

.grey:active {
	@apply bg-secondary-90 outline outline-[3px] outline-secondary-95;
}

.grey:disabled {
	@apply bg-secondary-90 opacity-30;
}

.transparent {
	@apply bg-transparent text-on-secondary-container;
}

.transparent:hover {
	@apply bg-primary-95 outline outline-[1px] outline-secondary-95;
}

.transparent:active {
	@apply bg-secondary-90 outline outline-[3px] outline-secondary-95;
}

.transparent:disabled {
	@apply bg-secondary-90 opacity-30;
}

.dark {
	@apply bg-secondary text-on-secondary;
}

.dark:hover {
	@apply bg-secondary-20;
}

.dark:active {
	@apply bg-secondary-20 outline outline-[3px] outline-secondary-80;
}

.dark:disabled {
	@apply bg-secondary-20 opacity-30;
}

.error:disabled {
	@apply opacity-30;
}

/* Sizes */

.base {
	@apply font-label-large p-[12px_20px];
}

.base.only-icon {
	@apply p-[10px];
}

.base.only-icon.outlined {
	@apply p-[9px];
}

.xs {
	@apply font-label-medium p-[8px_12px];
}

/* Возможно стоит это в принципе к XS применить, но есть вероятность что поедут другие варианты кнопки */
.dark.xs {
	@apply p-[7px_12px];
}

.xs.only-icon {
	@apply p-[4px];
}

.sm {
	@apply font-label-large p-[8px_12px];
}

.sm.only-icon {
	@apply p-[8px];
}

.l {
	@apply font-label-xlarge p-[12px_20px];
}

.l.only-icon {
	@apply p-[12px];
}

.xl {
	@apply font-label-xlarge p-[13px_24px];
}

.xl.only-icon {
	@apply p-[13px];
}

/* Modifications */

.transparent.outlined {
	@apply border-neutral-80;
}

.primary.outlined {
	@apply bg-transparent border-primary text-primary;
}

.primary.outlined:hover {
	@apply bg-primary-30 text-on-primary;
}

.primary.outlined:active {
	@apply bg-primary-30 text-on-primary outline outline-[3px] outline-primary-95;
}

.primary.outlined:disabled {
	@apply bg-primary-30 text-on-primary opacity-30;
}

.error.outlined {
	@apply bg-transparent border-error text-error;
}

.error.outlined:hover {
	@apply bg-error-30 text-on-primary;
}

.error.outlined:active {
	@apply bg-error-30 text-on-primary outline outline-[3px] outline-primary-95;
}

.error.outlined:disabled {
	@apply bg-transparent text-error opacity-30;
}

.grey.outlined {
	@apply bg-transparent border-outline text-on-background;
}

.grey.outlined:hover {
	@apply bg-surface-variant outline outline-[1px] outline-outline;
}

.grey.outlined:active {
	@apply bg-surface-variant outline outline-[3px] outline-outline;
}

.grey.outlined:disabled {
	@apply bg-surface-variant opacity-30;
}

.dark.outlined {
	@apply bg-transparent border-secondary text-secondary;
}

.dark.outlined:hover {
	@apply bg-secondary-20;
}

.dark.outlined:active {
	@apply bg-secondary-20 outline outline-[3px] outline-secondary-80;
}

.dark.outlined:disabled {
	@apply bg-secondary-20 opacity-30;
}
