.img-full {
	position: absolute;
	z-index: 1;
	left: calc(195px + 16px);
	top: 0;

	width: 50vw;
	border-radius: 12px;
	box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.25);
	aspect-ratio: 1.8/1;

	overflow: hidden;
}

.img-full img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
