.input {
	@apply appearance-none w-[24px] h-[24px] rounded-[4px] border border-neutral-92 bg-primary-100;
	transition: background-color 0.12s ease, border-color 0.12s ease;
}

.icon {
	@apply absolute left-[4px] top-[4px] text-primary-100 opacity-0 z-0 pointer-events-none;
	transition: opacity 0.12s ease;
}

.input.input--checked {
	@apply bg-primary border-primary;
}

.input--checked + .icon {
	@apply opacity-100;
}

.input:not(:disabled) {
	cursor: pointer;
}

/*.input {*/
/*	@apply relative appearance-none;*/
/*	@apply w-[40px] h-[24px] rounded-[24px];*/
/*	@apply border-[2px] border-neutral-92 bg-neutralVariant-80;*/
/*}*/

/*.input:after {*/
/*	content: '';*/
/*	@apply absolute block w-[20px] h-[20px] rounded-[20px] bg-primary-100;*/
/*	@apply left-0 top-0;*/
/*	transition: left 0.216 ease;*/
/*}*/

/*.input.input--checked {*/
/*	@apply bg-primary border-primary;*/
/*}*/

/*.input.input--checked:after {*/
/*	@apply left-[16px];*/
/*}*/

/*.input:not(:disabled) {*/
/*	cursor: pointer;*/
/*}*/
