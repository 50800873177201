.container {
	@apply inline-flex rounded-[24px] w-fit;
}

.container.squared {
	@apply rounded-2;
}

/**
 * SIZES
 */

.xs {
	@apply px-2 py-0;
}

.sm {
	@apply px-[12px] py-[2px];
}

.outlined.sm {
	@apply px-[11px] py-[1px];
}

.lg {
	@apply px-[16px] py-[6px];
}

.outlined.lg {
	@apply px-[15px] py-[5px];
}

/**
 * CONTENT
 */

.content {
	@apply flex items-center font-label-large gap-x-[4px] whitespace-nowrap;
}

/**
 * COLOR: PRIMARY
 */
.squared.primary,
.filled.primary {
	@apply bg-primary text-on-primary;
}

.contained.primary {
	@apply bg-primary-container text-primary;
}

.outlined.primary {
	@apply bg-transparent border border-primary text-primary;
}

/**
 * COLOR: TERTIARY
 */
.squared.tertiary,
.filled.tertiary {
	@apply bg-tertiary-80 text-on-tertiary;
}

.contained.tertiary {
	@apply bg-tertiary-container text-tertiary;
}

.outlined.tertiary {
	@apply bg-transparent border border-tertiary text-tertiary;
}

/**
 * COLOR: SECONDARY
 */
.squared.secondary,
.filled.secondary {
	@apply bg-secondary text-on-secondary;
}

.contained.secondary {
	@apply bg-secondary-90 text-on-secondary-container;
}

.outlined.secondary {
	@apply bg-transparent border border-secondary text-secondary;
}

/**
 * COLOR: GREEN
 */
.squared.green,
.filled.green {
	@apply bg-green text-green-100;
}

.contained.green {
	@apply bg-green-container text-green;
}

.outlined.green {
	@apply bg-transparent border border-green text-green;
}

/**
 * COLOR: ERROR
 */
.squared.error,
.filled.error {
	@apply bg-error text-on-error;
}

.contained.error {
	@apply bg-error-container text-error;
}

.outlined.error {
	@apply bg-transparent border border-error text-error;
}
