.root {
	@apply flex gap-x-[12px] items-start cursor-pointer;
}

.root:not(.disabled) input {
	@apply cursor-pointer;
}

.root.disabled {
	@apply cursor-default;
}

.root.disabled > div > p {
	@apply text-neutral-80;
}

.dark * {
	color: #00468b !important;
}
