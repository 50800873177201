.container {
	@apply flex items-center justify-center w-[20px] h-[20px] rounded-[100%];
}

.circle {
	@apply w-[10px] h-[10px] rounded-[100%];
}

/* SEVERITY: REGULAR */

.container.regular {
	@apply bg-green-container;
}

.circle.regular {
	@apply bg-green-50;
}

/* SEVERITY: CRITICAL */

.container.critical {
	@apply bg-error-80;
}

.circle.critical {
	@apply bg-error;
}

/* SEVERITY: MINOR */

.container.minor {
	@apply hidden;
}

.circle.minor {
	@apply hidden;
}
