.radio input {
	display: none;
}

.label {
	@apply transition-colors font-body-small font-medium py-2 px-3 rounded-2 border border-neutral-80 cursor-pointer;
}

input:checked + .label {
	@apply bg-primary-95 border-primary-95;
}
