.modal {
	@apply bg-primary-container absolute right-auto bottom-auto px-6 pt-8 pb-6 rounded-[16px] box-shadow-elevation-5 max-h-[80%] max-w-[80%] overflow-hidden;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
}

.close-button {
	@apply ml-auto;
}

.close-button svg path {
	@apply fill-on-primary-container;
}

.header {
	@apply sticky left-0 flex justify-between gap-5 items-center text-on-primary-container mb-[20px];
}

.overlay {
	@apply opacity-0 transition-opacity duration-[250ms] ease-in-out fixed left-0 top-0;
	background: rgba(85, 95, 113, 0.3);
	height: 100vh;
	width: 100vw;
	z-index: 30;
}

.overlay-after-open {
	@apply opacity-100;
}

.overlay-before-open {
	@apply opacity-0;
}
