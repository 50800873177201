.container {
	display: flex;
	padding: 8px;
	align-items: center;
	gap: 16px;

	width: min-content;
	border-radius: 8px;
	background: #ecf0ff;

	/* Elevation/3 */
	box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.1), 0 4px 6px -1px rgba(16, 24, 40, 0.1);
}

.left-icon {
	transform: rotate(90deg);
}
.right-icon {
	transform: rotate(-90deg);
}
