.region__flag {
	@apply absolute h-[12px] left-0 bottom-0 flex justify-center w-full gap-x-[8px] pointer-events-none items-center;
}

.region {
	@apply relative border border-outline rounded-[8px] h-[32px] w-[65px] text-[18px] leading-[18px] text-center font-medium bg-primary-100;
}

.number {
	@apply text-center bg-primary-100 h-[32px] border border-outline rounded-[8px] px-[10px] font-headline-small;
}
