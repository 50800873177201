.tab {
	@apply p-[10px_20px] font-title-large text-secondary whitespace-nowrap max-w-full overflow-hidden;
}

.tab svg {
	@apply flex-shrink-0;
}

.pill {
	@apply rounded-[8px];
}

.pill:hover {
	@apply bg-surface text-on-surface;
}

.pill.active {
	@apply bg-primary-container text-primary;
}

.pill:disabled {
	@apply bg-transparent text-neutral-30 opacity-50;
}
