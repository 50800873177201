.content {
	@apply flex flex-col gap-8 overflow-auto;
	flex-shrink: 0;
	flex-grow: 1;
	width: calc(40% - 32px);
	min-width: 480px;
}

.video-wrapper {
	@apply relative overflow-hidden rounded-2;
	width: 100%;
	max-width: 980px;
	aspect-ratio: 16/9;
	user-select: none;
	height: max-content;
}

.video-wrapper video {
	object-fit: fill;
}

.preview,
.error {
	@apply flex flex-grow h-full rounded-2 bg-secondary-container items-center justify-center;
}

.preview svg {
	width: 120px;
	height: 120px;
}

.preview svg path {
	@apply fill-primary-90;
}

.error svg path {
	@apply fill-error-80;
}
