.card {
	@apply bg-primary-100 rounded-3 outline outline-outline-variant  flex gap-6 items-center cursor-pointer ml-1;
}

.card svg {
	@apply text-primary-40;
}

.card.active {
	@apply outline-2 outline-primary-40;
	background: #ecf5ff;
}

.card.m {
	padding: 24px 24px 20px 24px;
	@apply flex-col text-center;
}

.card.m svg {
	width: 120px;
}

.card.s {
	padding: 24px 32px;
	@apply justify-center;
}

.card.s svg {
	width: 100px;
}
