.container {
	@apply overflow-hidden absolute w-full rounded-[12px];

	right: calc(-100% - 32px);
	transition-timing-function: ease-out;
	transition: all 0.3s;
	visibility: hidden;
	width: 0;
	height: 100%;
}

.container-active {
	transition-timing-function: ease-in;
	right: 0;
	visibility: visible;
	width: 100%;
}

.image {
	@apply relative z-20 object-fill w-full min-h-full aspect-video;
	box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.25);
}
