/**
 * ROOT
 */

.select {
}

/**
 * SIZES
 */

.default {
}

.lg {
}

/**
 * CONTROL
 */

.select :global div[class$='control'] {
	@apply p-[12px] py-[12px] border-none bg-secondary-container rounded-[8px] cursor-pointer;
}

.select.lg :global div[class$='control'] {
	@apply p-[14px_16px];
}

.select--error :global div[class$='control'] {
	@apply bg-error-container;
}

/**
 * VALUE CONTAINER
 */

.select :global div[class$='control'] > div:first-child {
	@apply p-0 text-secondary font-body-medium flex gap-x-[8px] flex-nowrap;
}

.select.lg :global div[class$='control'] > div:first-child {
	@apply font-body-large;
}

.select.lg :global div[class$='control'] > div:first-child svg {
	@apply flex-shrink-0;
}

.valueContainer--MenuOpen div {
	@apply text-on-secondary-container !important;
}

.select--error :global div[class$='control'] > div:first-child {
	@apply text-error;
}

.select--error .valueContainer--MenuOpen div {
	@apply text-error !important;
}

/**
 * INPUT
 */

.select :global div[class$='Input'] {
	@apply m-0 p-0;
}

.select :global input,
.select:not(:has(*:focus)) :global div[class$='control'] > div:first-child {
	/*@apply text-secondary !important;*/
}

.select:has(*:focus) :global input,
.select:has(input:focus) :global input,
.select:has(input:focus) :global div[class$='control'] > div:first-child {
	@apply text-secondary !important;
}

.select.select--error:has(*:focus) :global input,
.select.select--error:has(input:focus) :global input,
.select.select--error:has(input:focus) :global div[class$='control'] > div:first-child {
	@apply text-error !important;
}

.select:has(*:focus) :global div[class$='placeholder'] {
	display: none;
}

/**
 * VALUE ICON
 */

.select:has(*:focus) .value-icon {
	@apply text-primary;
}

.select.select--error:has(*:focus) .value-icon,
.select--error .value-icon {
	@apply text-error;
}

/**
 * PLACEHOLDER
 */

.select :global div[class$='placeholder'] {
	@apply m-0 text-secondary ellipsis-clamp;
}

.select--error :global div[class$='placeholder'] {
	@apply text-error;
}

/**
 * INDICATOR SEPARATOR
 */

.select :global span[class$='indicatorSeparator'] {
	@apply hidden;
}

/**
 * INDICATOR CONTAINER
 */

.select :global div[class$='indicatorContainer'] {
	@apply p-0;
}

/**
 * ARROW ICON
 */

.dropdownIcon {
	@apply text-secondary w-[18px] h-[18px];
}

.select--error .dropdownIcon {
	@apply text-error !important;
}

/**
 * MENU
 */

.select :global div[class$='menu'] {
	@apply my-[12px] py-[16px] rounded-[8px] bg-secondary-container box-shadow-elevation-2;
}

.select.lg :global div[class$='menu'] div[class$='menu'] {
	@apply my-[8px];
}

/**
 * OPTION
 */

.option {
	@apply p-[8px_16px] text-secondary font-body-medium cursor-pointer flex;
}

.option--selected {
	@apply bg-secondary-90;
}
