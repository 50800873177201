.checkbox input,
.checkboxItem input {
	display: none;
}

.label {
	@apply transition-colors font-body-small font-medium py-2 px-3 rounded-2 border border-neutral-80 cursor-pointer;
}

input:checked + .label {
	@apply bg-primary-95 border-primary-95;
}

.checkboxItem {
	@apply relative;
}

.labelItem {
	@apply relative w-full block transition-colors text-14 font-medium text-secondary-40 py-2 pl-13 pr-6 cursor-pointer;
}

input:disabled + .labelItem {
	@apply text-neutral-60 cursor-not-allowed;
}

input:disabled + .labelItem:before {
	@apply bg-neutral-80 border-neutral-60;
}

.checkboxContainer,
.checkboxContainerActive {
	@apply absolute left-4  w-6 h-6 border border-neutral-80 rounded bg-no-repeat;
	top: calc(50% - 12px);
}

.checkboxContainer {
	@apply bg-background;
}

.checkboxContainerActive {
	@apply bg-primary;
}

.checkboxIcon {
	@apply absolute;
	left: calc(50% - 7px);
	top: calc(50% - 5px);
}
