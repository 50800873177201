.toast {
	background: unset !important;
	padding: unset !important;
	box-shadow: unset !important;
	width: max-content !important;
}

.body {
	padding: unset !important;
}

.body > div:first-child {
	display: none;
}
