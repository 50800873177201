.container {
	@apply max-h-[50vh] overflow-auto min-h-[150px];
}

.container::after {
	content: '';
	display: block;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
	position: absolute;
	bottom: 80px;
	left: 0;
	width: 100%;
	height: 50px;
	pointer-events: none;
}
