.zone {
	padding: 6px 12px;
	align-items: center;
	align-self: stretch;

	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.zone span {
	@apply truncate;
	width: 300px;
}

.zone:hover {
	@apply bg-primary-80;
	cursor: pointer;
}
