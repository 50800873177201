.preview {
	@apply flex flex-grow h-full rounded-2 bg-secondary-container items-center justify-center;
}

.preview svg {
	width: 120px;
	height: 120px;
}

.preview svg path {
	@apply fill-primary-90;
}
