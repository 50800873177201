.step {
	@apply flex flex-col gap-y-[8px];
}

.circle {
	@apply flex justify-center items-center;
	@apply w-[40px] h-[40px] border-[2px] border-outline-variant rounded-[40px] flex-shrink-0;
}

.line {
	@apply h-[2px] w-full bg-outline-variant;
}

/**
 * Active
 */

.step--active .circle {
	@apply border-primary;
}

.step--active .circle:after {
	content: '';
	position: absolute;
	display: block;
	left: 10px;
	top: 10px;
	width: 20px;
	height: 20px;
	border-radius: 20px;
	@apply bg-primary;
}

.step--active .line {
	@apply bg-primary;
}

/**
 * Done
 */

.step--done .circle {
	@apply border-primary bg-primary;
}

.step--done .line {
	@apply bg-primary;
}
