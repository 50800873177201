.box {
	position: relative;
	width: 100%;
	min-width: 250px;
}

/*добиваемся соотношения сторон 16:9*/
.box:before {
	content: '';
	display: block;
	padding-top: 56.25%;
	/* ratio of 16:9*/
}

.content {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	overflow: hidden;
}
