.drawer {
	@apply h-[100vh] sticky top-0 flex flex-col gap-y-[6px] bg-primary-100 w-[293px] flex-shrink-0;
	box-shadow: 4px 0 24px rgba(193, 193, 193, 0.12);
	z-index: 1;
}

.drawer--collapse {
	@apply w-[68px];
}

.drawer--collapse .logo {
	display: none;
}

.drawer--collapse .collapse {
	@apply rotate-180;
}

/* Link */

.link {
	@apply p-[9px_12px_9px_24px] rounded-[0px_8px_8px_0px] flex items-center gap-x-[12px] w-[96%];
}

.link__label {
	@apply font-body-large text-on-surface;
	@apply ellipsis-clamp text-left;
}

.link--active {
	@apply bg-primary text-on-primary;
}

.link--active .link__label {
	@apply text-primary-100;
}

/* Divider */

.divider {
	@apply relative font-body-small p-[9px_12px_9px_32px] opacity-50 my-[4px] overflow-hidden overflow-ellipsis;
	color: rgba(41, 42, 53, 0.87);
}

.divider:after {
	content: '';
	@apply absolute block w-[16px] h-[1px] top-[18px] left-[0];
	background: rgba(41, 42, 53, 0.87);
}

/* Footer */

.footer {
	@apply mt-auto border-t border-t-neutral-95 p-[16px_24px] flex items-center whitespace-nowrap;
}

.footer__credentials {
	@apply flex flex-col gap-y-[2px] max-w-[152px] ml-[16px];
}

.footer__exit {
	@apply ml-auto;
}

.drawer--collapse .footer {
	@apply flex justify-center items-center p-0 py-[17px];
}

.drawer--collapse .footer__credentials,
.drawer--collapse .footer__exit {
	display: none;
}
