.input {
	position: relative;
	appearance: none;
	width: 24px;
	height: 24px;
	background-color: white;
	border: 1px solid #e6e8f1;
	border-radius: 100%;
}

.input--checked {
	border-color: #015db5;
}

.input--checked::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 16px;
	height: 16px;
	background-color: #015db5;
	border-radius: 100%;
}
