.container {
	position: relative;
	overflow: hidden;
}

/**
 * Grid 1
 */

.grid-1 {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
}

/**
 * Grid 4
 */

.grid-4 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
}

/**
 * Grid 8
 */

.grid-8 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-areas:
		'cam1 cam1 cam1 cam2'
		'cam1 cam1 cam1 cam3'
		'cam1 cam1 cam1 cam4'
		'cam8 cam7 cam6 cam5';
}

.grid-8 > *:nth-child(1) {
	grid-area: cam1;
}

.grid-8 > *:nth-child(2) {
	grid-area: cam2;
}

.grid-8 > *:nth-child(3) {
	grid-area: cam3;
}

.grid-8 > *:nth-child(4) {
	grid-area: cam4;
}

.grid-8 > *:nth-child(5) {
	grid-area: cam5;
}

.grid-8 > *:nth-child(6) {
	grid-area: cam6;
}

.grid-8 > *:nth-child(7) {
	grid-area: cam7;
}
.grid-8 > *:nth-child(8) {
	grid-area: cam8;
}

/**
 * Grid 9
 */

.grid-9 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-areas:
		'cam1 cam1 cam2 cam3'
		'cam1 cam1 cam4 cam5'
		'cam6 cam7 cam8 cam9';
}

.grid-9 > *:nth-child(1) {
	grid-area: cam1;
}

.grid-9 > *:nth-child(2) {
	grid-area: cam2;
}

.grid-9 > *:nth-child(3) {
	grid-area: cam3;
}

.grid-9 > *:nth-child(4) {
	grid-area: cam4;
}

.grid-9 > *:nth-child(5) {
	grid-area: cam5;
}

.grid-9 > *:nth-child(6) {
	grid-area: cam6;
}

.grid-9 > *:nth-child(7) {
	grid-area: cam7;
}

.grid-9 > *:nth-child(8) {
	grid-area: cam8;
}

.grid-9 > *:nth-child(9) {
	grid-area: cam9;
}

/**
 * Grid 10
 */

.grid-10 {
	display: grid;
	/*grid-template-columns: repeat(7, 116.57px);*/
	/*grid-template-rows: repeat(4, 137.5px);*/
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-areas:
		'cam1 cam1 cam2 cam3'
		'cam1 cam1 cam4 cam5'
		'cam6 cam7 cam10 cam10'
		'cam8 cam9 cam10 cam10';
}

.grid-10 > *:nth-child(1) {
	grid-area: cam1;
}

.grid-10 > *:nth-child(2) {
	grid-area: cam2;
}

.grid-10 > *:nth-child(3) {
	grid-area: cam3;
}

.grid-10 > *:nth-child(4) {
	grid-area: cam4;
}

.grid-10 > *:nth-child(5) {
	grid-area: cam5;
}

.grid-10 > *:nth-child(6) {
	grid-area: cam6;
}

.grid-10 > *:nth-child(7) {
	grid-area: cam7;
}

.grid-10 > *:nth-child(8) {
	grid-area: cam8;
}

.grid-10 > *:nth-child(9) {
	grid-area: cam9;
}

.grid-10 > *:nth-child(10) {
	grid-area: cam10;
}

/**
 * Grid 12
 */

.grid-12 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
}
