.range {
	@apply flex items-center;
	gap: 5px;
}

.range span {
	@apply font-title-large;
}

.inputs {
	@apply flex gap-2;
}
