.item {
	display: flex;
	padding: 6px 12px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
}

.item:hover {
	@apply bg-primary-80;
	cursor: pointer;
}
