.off {
	@apply bg-primary-container;
}

.off svg {
	@apply text-secondary;
}

.off:hover {
	@apply bg-primary-80;
}

.on {
	@apply bg-primary;
}
